import React from 'react'
//header content

// sidebar conten 1
const  iconDashboard = require('./icons-nav/icon-dashboard.svg')
const  iconInspriation = require('./icons-nav/icon-inspriation.svg')
const  iconESGreporting = require('./icons-nav/icon-esgreporting.svg')

// sidebar conten 2
const  iconCircularMarketplace = require('./icons-nav/icon-circular-marketplace.svg')
const  iconRequestToMarketplace = require('./icons-nav/icon-request-to-marketplace.svg')
const  iconFavorites = require('./icons-nav/icon-favorites.svg')

// sidebar conten 3
const  iconManageOrders = require('./icons-nav/icon-manage-orders.svg')
const  iconManageFinances = require('./icons-nav/icon-manage-finances.svg')
const  iconCart = require('./icons-nav/icon-carts.svg')
const  iconSetting = require('./icons-nav/icon-setting.svg')

export const NavigationSidebarDataFirst = [
    {
        name: "dashboard",
        title: "Dashboard",
        icon: iconDashboard,
        link: "",
        secondPath: ""
    },
    {
        name: "inspriation",
        title: "Inspriation",
        icon: iconInspriation,
        link: "",
        secondPath: ""
    },
    {
        name: "esg_reporting",
        title: "ESG Reporting",
        icon: iconESGreporting,
        link: "",
        secondPath: ""
    }
]

export const NavigationSidebarDataSecond = [
    {
        name: "circular_marketplace",
        title: "Circular marketplace",
        icon: iconCircularMarketplace,
        link: "Catalogue",
        secondPath: ""
    },
    {
        name: "requests_to_marketplace",
        title: "Requests to marketplace",
        icon: iconRequestToMarketplace,
        link: "",
        secondPath: ""
    },
    {
        name: "favorites",
        title: "Favorites",
        icon: iconFavorites,
        link: "",
        secondPath: ""
    }
]

export const NavigationSidebarDataThird = [
    {
        name: "manage_orders",
        title: "Manage orders",
        icon: iconManageOrders,
        link: "",
        secondPath: ""
    },
    {
        name: "manage_finances",
        title: "Manage finances",
        icon: iconManageFinances,
        link: "",
        secondPath: ""
    },
    {
        name: "cart",
        title: "Cart",
        icon: iconCart,
        link: "",
        secondPath: ""
    },
    {
        name: "settings",
        title: "Settings",
        icon: iconSetting,
        link: "UserSettings",
        secondPath: "PersonalDetails"
    },
    
]
