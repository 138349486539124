import * as Yup from "yup";


export const PersonalDetailsSchema = Yup.object().shape({
  first_name: Yup.string()
    .nullable()
    .required("This field can’t be empty")
    .trim(),
  last_name: Yup.string()
    .nullable()
    .required("This field can’t be empty")
    .trim(),
  email: Yup.string()
    .required("This field can’t be empty")
    .email("Please enter a valid email address")
    .max(100, "Email should not be more than 100 characters long")
    .trim(),
  job_title: Yup.string()
    .required("This field can’t be empty")
    .nullable()
    .trim(),
  company_name: Yup.string()
    .nullable()
    .trim(),
  country: Yup.string()
    .required("This field can’t be empty")
    .nullable()
    .trim(),
  city: Yup.string()
    .required("This field can’t be empty")
    .nullable()
    .trim(),
  country_code: Yup.string()
    .required("This field can’t be empty")
    .nullable()
    .trim(),
  phone_number: Yup.string()
    .required("This field can’t be empty")
    .nullable()
    .min(6, "Phone number should not be less than 6 digit long")
    .max(15, "Phone number should not be more than 15 digit long")
    .trim(),
});

export const updatePasswordSchema = Yup.object().shape({
  current_password: Yup.string()
    .when('new_password', {
      is: (new_password) => new_password && new_password.length !== 0,
      then: Yup.string()
        .required('This field can’t be empty')
    })
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must have at least 8 characters and should contain: uppercase letter, lowercase letter, number, special character'),
  new_password: Yup.string()
    .when('current_password', {
      is: (current_password) => current_password && current_password.length !== 0,
      then: Yup.string()
        .required('This field can’t be empty')
        .test('passwords-match', 'New password should not be same as current password', function (value) {
          return this.parent.current_password !== value
        }),
    })
    .when('confirm_new_password', {
      is: (confirm_new_password) => confirm_new_password && confirm_new_password.length !== 0,
      then: Yup.string()
        .required('This field can’t be empty')
    })
    .max(100, "Password should not be more than 100 characters long")
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Password must have at least 8 characters and should contain: uppercase letter, lowercase letter, number, special character'),
  confirm_new_password: Yup.string()
    .when('new_password', {
      is: (current_password) => current_password && current_password.length !== 0,
      then: Yup.string()
        .required('This field can’t be empty')
        .oneOf([Yup.ref('new_password'), null], 'Password is not matched'),
    })
}, [["current_password", "new_password"], ["new_password", "confirm_new_password"]]);

export const CompanyDetailsSchema = Yup.object().shape({
  name: Yup.string()
  .nullable()
  .trim(),
  initial_address: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
  address: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
country: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
city: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
country_code: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
  zip_code: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .trim(),
phone_number: Yup.string()
  .required("This field can’t be empty")
  .nullable()
  .min(6, "Phone number should not be less than 6 digit long")
  .max(15, "Phone number should not be more than 15 digit long")
  .trim(),
});
