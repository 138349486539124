// Customizable Area Start
import React from "react";

import { styled } from "@mui/material/styles";
import { VisibilityOutlined, VisibilityOffOutlined, KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  TextField, 
  Button,
  FormControl,
  Grid,
  Typography,
  FormLabel,
  InputAdornment,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
import { bgImage, headerImg, arrowImg, mobileImg, tabImg,logoImage } from "./assets";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (

      <div>
         <Box>
          <StyledHeader>
            <img src={headerImg}/>
            <img style={{ height:"15px",marginTop:'20px' }} src={logoImage}/>
          </StyledHeader>
        </Box>
        <StyledContainer>
          <InnerContainer>
            <LeftContainer>
            <Typography
                    style={{
                      fontFamily: "brandon-grotesque, sans-serif",
                      fontSize: "32px",
                      color: "#F8D18D",
                      fontWeight: "420",
                    }}
                  >
                    SIGN UP
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "white",
                      fontWeight: "420",
                      fontFamily: "brandon-grotesque, sans-serif",
                    }}
                  >
                    I HAVE AN ACCOUNT, I WANT TO{" "}
                    <span data-test-id="signInButton"> <a href="/EmailAccountLoginBlock" style={{fontFamily: "brandon-grotesque, sans-serif",textDecoration:"none",color: "#F8D18D"}}>SIGN IN </a></span>{" "}
                  </Typography>
                  <Button sx={styles.customButton}>
                    <img src={arrowImg} style={{backgroundColor: "#F5F1E2 !important"}}/>
                    BOOK A DEMO
                  </Button>
                  <Box >
                    <StyledLabel1>
                      Begin Your Journey
                    </StyledLabel1>
                    <Typography style={styles.subHead}>
                      Start your journey on the premium circular economy
                      platform, dedicated to advancing sustainability in the
                      apparel sector. For more information, a personalised demo
                      or to chat with our sustainability experts, please contact
                      us here.
                    </Typography> 
                    <Typography style={styles.secondPara}>
                      If you’d like to chat with our team directly, please contact us on
                      +44 (0) 20 8064 0327.
                    </Typography>
                  </Box>
            </LeftContainer>
            <RightContainer>
            <SignInContainer>
                <form  onSubmit={this.handleSubmit} data-test-id="registrationForm" style={{width:"100%"}}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <FormLabel
                      component="legend"
                      style={{
                        color: "#616161",
                        fontFamily: "brandon-grotesque, sans-serif",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      I'M A
                      <span
                        data-test-id="buyerOption"
                        style={this.roleStyle("buyer")}
                        onClick={() => this.handleUserTypeChange("buyer")}
                      >
                        BUYER
                      </span>
                      <span style={{color:"#616161", marginLeft:"5px",}}>|</span>
                      <span
                        data-test-id="supplierOption"
                        style={this.roleStyle("seller")}
                        onClick={() => this.handleUserTypeChange("seller")}
                      >
                        SUPPLIER
                      </span>
                      <span style={{color:"#616161", marginLeft:"5px"}}>|</span>

                      <span
                        data-test-id="bothOption"
                        style={this.roleStyle("both")}
                        onClick={() => this.handleUserTypeChange("both")}
                      >
                        BOTH
                      </span>
                    </FormLabel>
                  </FormControl>
                  {
                    this.state.fType === 'signUp' ? <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledLabel>First name</StyledLabel>
                      <StyledTextField
                          fullWidth
                          data-test-id="firstNameInput"
                          placeholder="Write your name"
                          name="firstName"
                          id="standard-error-helper-text"
                          value={this.state.firstName}
                          onChange={(event)=>this.handleChange('firstName' ,event.target.value)}
                          helperText={this.state.firstNameError}
                          error={!!this.state.firstNameError}
                          sx={{
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#F8FAFC',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#292524',
                              },
                            },
                          }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div style={{marginTop:"12px"}}></div>
                      <StyledLabel>Job title</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="jobTitleInput"
                        placeholder="Your job title"
                        name="jobTitle"
                        value={this.state.jobTitle}
                        onChange={(event)=>this.handleChange('jobTitle' ,event.target.value)}
                        helperText={this.state.jobTitleError}
                        error={!!this.state.jobTitleError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Surname</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="surnameInput"
                        placeholder="Write your surname"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={(event)=>this.handleChange('lastName' ,event.target.value)}
                        helperText={this.state.lastNameError}
                        error={!!this.state.lastNameError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Company name</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="companyNameInput"
                        placeholder="Your company name"
                        name="companyName"
                        value={this.state.companyName}
                        onChange={(event)=>this.handleChange('companyName' ,event.target.value)}
                        helperText={this.state.companyNameError}
                        error={!!this.state.companyNameError}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Work email</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="emailInput"
                        placeholder="name@companyname.com"
                        name="workEmail"
                        type="email"
                        value={this.state.email}
                        onChange={(event)=>this.handleChange('email' ,event.target.value)}
                        error={!!this.state.emailError}
                        helperText={this.state.emailErrorMessage}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Country</StyledLabel>
                      <CustomSelect
                          fullWidth
                          data-test-id="countryName"
                          IconComponent={KeyboardArrowDown}
                          name="country"
                          value={this.state.selectedCountry}
                          onChange={this.handleCountryChange}
                          displayEmpty
                          error={!!this.state.countryError}
                          style={this.state.selectedCountry ==='' ? {
                          border:"1 solid",
                          color:'rgba(100, 116, 139, 0.42)'
                          }: {color:'#292524'}}
                          sx={{
                            color:"#64748B",
                            backgroundColor: "#F8FAFC",
                            width:"100%",
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#292524',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#F8FAFC',
                            },
                            '.MuiSvgIcon-root ': {
                              color:"#94A3B8",
                            },
                          }}
                        >
                          <MenuItem value="" style={{color:"#64748B"}}>
                            Country Name
                          </MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="United Kingdom" value="United Kingdom">United Kingdom</MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="Germany" value="Germany">Germany</MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="Spain" value="Spain">Spain</MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="France" value="France">France</MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="Italy" value="Italy">Italy</MenuItem>
                          <MenuItem style={{color:"#64748B" }} key="Portugal" value="Portugal">Portugal</MenuItem>
                        </CustomSelect>
                        <FormHelperText error={!!this.state.countryError}>
                          {this.state.countryError}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Password</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="passwordInput"
                        placeholder="*******"
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={(event)=>this.handleChange('password' ,event.target.value)}
                        error={!!this.state.passwordError}
                        helperText={this.state.passwordErrorMessage}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                            data-testid="showPasswordContainer"
                            data-show={this.state.showPassword}
                            position="end"
                          >
                              {this.state.showPassword ? <VisibilityOutlinedIcon 
                                data-test-id="togglePasswordVisibility"
                                style={{cursor: "pointer",color:"#94A3B8",height:'16px',width:"16px"}}
                                data-testid="hidePassword" onClick={this.togglePasswordVisibility} 
                                /> :
                                <VisibilityOffOutlinedIcon 
                                data-test-id="togglePasswordVisibility"
                                style={{cursor: "pointer",color:"#94A3B8", height:'16px',width:"16px"}}
                                data-testid="showPassword" onClick={this.togglePasswordVisibility} />}
                          </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>City</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="cityInput"
                        placeholder="City name"
                        name="city"
                        value={this.state.city}
                        onChange={(event)=>this.handleChange('city' ,event.target.value)}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                        helperText={this.state.cityError}
                        error={!!this.state.cityError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Confirm Password</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="confirmPasswordInput"
                        placeholder="*******"
                        name="confirmPassword"
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        value={this.state.confirmPassword}
                        onChange={(event)=>this.handleChange('confirmPassword' ,event.target.value)}
                        error={!!this.state.confirmPasswordError}
                        helperText={this.state.confirmPasswordErrorMessage}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              data-testid="showConfirmPasswordContainer"
                              data-show={this.state.showConfirmPassword}
                              position="end"
                            >
                                {this.state.showConfirmPassword ? <VisibilityOutlinedIcon 
                                data-test-id="toggleConfirmPasswordVisibility"
                                style={{cursor: "pointer",color:"#94A3B8",height:'16px',width:"16px"}}
                                data-testid="hidePassword" onClick={this.toggleConfirmPasswordVisibility} 
                                /> :
                                <VisibilityOffOutlinedIcon 
                                data-test-id="toggleConfirmPasswordVisibility"
                                style={{cursor: "pointer",color:"#94A3B8", height:'16px',width:"16px"}}
                                data-testid="showPassword" onClick={this.toggleConfirmPasswordVisibility} />}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledLabel>Phone</StyledLabel>
                      <StyledTextField
                        fullWidth
                        data-test-id="phoneInput"
                        placeholder="000 0000 000 "
                        name="phone"
                        type="tel"
                        value={this.state.phone}
                        onChange={(event)=>this.handleChange('phone' ,event.target.value)}
                        sx={{
                          width: '100%',
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#F8FAFC',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#292524',
                            },
                          },
                        }}
                        helperText={this.state.phoneError}
                        error={!!this.state.phoneError}
                        InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                          >
                            <span style={{color: '#292524'}}>{this.state.countryCode ? "+"+ this.state.countryCode : ''}</span>
                          </InputAdornment>
                        ),
                      }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} style={{marginTop:"10px"}}>
                    <Box style={{display:"flex"}}> 
                        <StyledCheckbox 
                          value={this.state.tncChecked} 
                          onChange={(event)=>this.handleCheckboxChange(event.target.checked)} 
                          data-test-id="tncInput"
                          sx={{ '& .MuiSvgIcon-root':{borderColor: this.getColor("#A8A29E")}}}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            fontFamily: "brandon-grotesque, sans-serif",
                            fontSize: "14px",
                            fontWeight: "390",
                            color: this.getColor("#292524"),
                            marginTop:"13px"
                          }}
                        >
                          By clicking you agree to our{" "}
                          <a
                            href="/TermsConditions"
                            style={{
                              textDecoration: "none",
                              color: this.getColor("#BA902F"),
                              cursor:"pointer"
                            }}
                          >
                            Terms of use
                          </a>{" "}
                          and{" "}
                          <a
                            href="#"
                            style={{
                              textDecoration: "none",
                              color: this.getColor("#BA902F"),
                              cursor:"pointer"
                            }}
                          >
                            Privacy Policy
                          </a>
                        </Typography>
                      </Box>
                      <Box style={{display:"flex"}}>
                          <StyledCheckbox />
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "brandon-grotesque, sans-serif",
                              fontSize: "14px",
                              fontWeight: "390",
                              color: "#292524",
                              marginTop:"13px",
                            }}
                          >
                            By clicking you agree to receiving marketing activity from ProcureCircular. (Optional)
                          </Typography>
                    </Box>
                  </Grid>                   
                  <Button
                    type="submit"
                    onClick={(e)=>this.handleSubmit(e)}
                    data-test-id="registerButton"
                    style={{
                      color: "#B7862B",
                      borderRadius: "36px",
                      width: "100%",
                      marginTop: "15px",
                      border: "1px solid #B7862B",
                      fontSize:"16px"
                    }}
                  >
                    GET STARTED
                  </Button>
                  </>
                  : ""
                }
                {
                  this.state.fType === 'companyError' ? <>
                  <CompanyContainer>
                      <CompanyStyledText>Please contact the account holder </CompanyStyledText>
                      <CompanyStyledText> with administrative access </CompanyStyledText>
                      <CompanyStyledText>to create an account on your behalf</CompanyStyledText>
                  </CompanyContainer>
                   <Button
                      type="submit"
                      onClick={()=>this.clickSignUp()}
                      data-test-id="signUpButton"
                      style={{
                        backgroundColor: "#1C1917",
                        color: "#FAFAF9",
                        borderRadius: "36px",
                        width: "100%",
                        marginTop: "15px",
                        border: "1px solid #B7862B",
                        fontSize:"16px",
                        height:"44px",
                        fontWeight:"390"
                      }}
                    >
                  RETURN TO SIGNUP
                </Button>
                  </> : ""
                }
                </form>
            </SignInContainer>
            </RightContainer>
          </InnerContainer>
          </StyledContainer>
      </div>
    );
  }
}
const StyledHeader = styled(Box)({
  marginTop: '30px',
    marginLeft: '50px',
    display:"flex",
    marginRight:"2%",
    "@media(max-width:500px)": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    "@media(min-width:500px) and (max-width:850px)": {
      justifyContent: "space-between",
      flexDirection: "row-reverse",

    }
});
const SignInContainer= styled(Box)({
  padding:'25px',
  width: "100%",
  height: "auto",
  display: "flex",
  "@media(max-width:500px)": {
    width:"90%",
    paddingTop:'32px'
  },
  "@media(min-width:500px) and (max-width:850px)": {
    paddingLeft:"12px",
  }
})
const CompanyContainer =styled(Box)({
  width: "100%",
  height: "400px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})
const StyledContainer = styled("div")({
  height: "100%",
  width: "96%",
  marginLeft: '2%',
  marginRight: '2%',
  marginTop:"10px",
  backgroundImage:`url(${bgImage})`,
  outline: '1px solid #CCB26F',
  outlineOffset: '-21px',
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center',
  backgroundSize: "cover",
  "@media(max-width:500px)": {
    backgroundImage:`url(${mobileImg})`,
    width: "100%",
    marginLeft: '0',
    marginRight: '0',
    backgroundSize: "cover",
    outline: '1px solid #CCB26F',
    outlineOffset: '-21px',
    height:"auto"
  },
  "@media(min-width:500px) and (max-width:850px)": {
    backgroundImage:`url(${tabImg})`,
    width: "100%",
    marginLeft: '0',
    marginRight: '0',
    backgroundSize: "cover",
    outline: '1px solid #CCB26F',
    outlineOffset: '-21px',
  },
});
const InnerContainer= styled(Box)({
  padding:"10px", 
  display: "flex",
  alignItems: "start",
  height: "auto", 
  width:"85%",
  justifyContent:"space-between",
  "@media(max-width:500px)": {
    width:"90%",
    flexDirection: "column",
    alignItems: "center",
  },
  "@media(min-width:500px) and (max-width:850px)": {
    width:"90%",
    alignItems: "center",
  }
})
const LeftContainer= styled(Box)({
  padding:"33px",
  flexDirection: 'column',
  alignContent: 'center',
  display: 'flex',
  width: "37%",
  marginTop:"30px",
  height: "auto",
  opacity: "0px",
  "@media(max-width:500px)": {
    width:"90%",
    padding:'0px'
  }
})
const RightContainer= styled(Box)({
  width: '47%',
  height: 'auto',
  backgroundColor: 'white',
  display: 'flex',
  opacity: "0px",
  border: '4px solid #BA9553',
  "@media(max-width:500px)": {
    width:"90%",
    flexDirection: "column",
    marginTop:"20px",
    marginBottom:"20px",
    alignItems: "center",
    justifyContent:"center"
  }
})
const StyledLabel = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
  lineHeight: "20px",
  color: "#1E293B",
  letterSpacing: "0.05em",
  marginTop:"-8px"
});

const CompanyStyledText = styled(Typography)({
  fontFamily: "garamond-premier-pro !important",
  fontSize: "24px",
  fontWeight: 400,
  textAlign: "center",
  color: "#616161",
  lineHeight: "28.8px"

});
const StyledLabel1 = styled(Typography)({
  fontWeight: "400",
  marginTop:"20px",
  fontSize: "48",
  color: "white",
  fontFamily: 'garamond-premier-pro !important',
});
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: "#64748B",
    fontSize: "14px",
    height: "36px",
    backgroundColor: "#F8FAFC",
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: "#DC2626",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: '1px solid transparent',
  },
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
    fontFamily: "brandon-grotesque, sans-serif",
    marginLeft: "initial",
    color:'#DC2626',
  },
  '& .MuiFormHelperText-root.Mui-error':{
    color:'#DC2626',
  },
  '& .Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "#292524",
    },
  },
  '& .MuiInputBase-input': {
    height: '2px',
    color: '#292524',
  },
'& .MuiInputBase-input::placeholder': {
  color: '#64748B', 
},
});


const CustomSelect = styled(Select)({
  height: '36px',
  color: "#64748B",
  fontSize: '14px',
  backgroundColor: '#F8FAFC',
  fontFamily: "brandon-grotesque, sans-serif",
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid transparent',
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: "#DC2626",
  },
});

const StyledIconButton = styled(IconButton)({
  color: '#94A3B8',
});

const StyledCheckbox = styled(Checkbox) ({
  '& .MuiSvgIcon-root': {
    fontSize: 36,
    borderRadius:"6.452px",
    border:"2.613px solid #A8A29E",
    backgroundColor:"#F5F5F4",
    height:"25px",
    width:"25px"
  },
  '&.Mui-checked':{
    color:'#A8A29E',
  }
});

const styles = {
  header: {
    marginTop: "55.73",
    marginLeft: "55",
  },
  customButton: {
    fontWeight: "420",
    width: "178px",
    height: "44px",
    gap: "5px",
    display: "flex",
    fontSize: "16px",
    cursor: "pointer",
    color: "#B07A25 !important",
    marginTop: "55px !important",
    borderRadius: "36px !important",
    border: "1px solid #B07A25 !important",
    backgroundColor: "#F5F1E2 !important",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  subHead: {
    marginTop:"15px",
    fontSize: "16px",
    color: "white",
    fontFamily: "brandon-grotesque, sans-serif",
  },
  secondPara: {
    fontSize: "16px",
    color: "white",
    marginTop: "20px",
    fontFamily: "brandon-grotesque, sans-serif",
  }
};
// Customizable Area End