import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import {apiCall, getActivePath, onHandleNavigation} from "../../../blocks/utilities/src/CommonFunctions";
import { AccountAttributes, AccountResponse } from "../../../blocks/utilities/src/typeInterface";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  userInfo: Partial<AccountAttributes>
  isSupplier: boolean;
  activePath: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationWrapperController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLoggedUsersApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      isSupplier: false,
      activePath: "",
      userInfo: {
        activated: true,
        country_code: "",
        email: "",
        first_name: "",
        last_name: "",
        full_phone_number: "",
        phone_number: "",
        account_image: "",
        type: "",
        country: "",
        city: "",
        company_name: "",
        job_title: "",
        created_at: "",
        updated_at: "",
        device_id: "",
        unique_auth_id: ""
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiCallId && responseJsonData) {
        if (apiCallId === this.getLoggedUsersApi) {
          this.getLoggedUserResponse(responseJsonData);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("accessToken");
    this.getLoggedUser();

    this.setState({ 
      token: authToken
    });
    this.checkActivePath();
  }

  getLoggedUser = async () => {
    this.getLoggedUsersApi = await apiCall({ method: configJSON.getApiMethodType, endPoint: configJSON.getProfileDetailsEndpoint, token: true });
  }

  getLoggedUserResponse = (responseData: AccountResponse) => {
    if (responseData.data.attributes) {
      const { attributes } = responseData.data
      this.setState({
        userInfo: attributes,
        isSupplier: attributes?.account_type === "supplier"
      })
    }
  }

  checkActivePath = () => {
    this.setState({ activePath: getActivePath(this.props) })
  }

  onHandleNavigation = (path: string, secondPath?:string) => {
    if (path) {
      this.send(onHandleNavigation(this.props, path, secondPath))
    }
  };

  onHandleBuyerSupplier = (isSupplier: boolean) => {
    this.setState({ isSupplier })
  };  

  onHandleLogout = () => {
    removeStorageData("accessToken")
    this.onHandleNavigation("EmailAccountLoginBlock")
  };

  // Customizable Area End
}
