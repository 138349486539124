export const LeftArrow = require("../assets/LeftArrowImg.svg");
export const RightArrow = require("../assets/RightArrowImg.svg");
export const CatalogueBanner = require("../assets/bannerCatalogue.png");
export const FilterImage = require("../assets/filterImage.svg");
export const MapImage = require("../assets/map.svg");
export const EqualImage = require("../assets/equalImage.svg");
export const MenuItemImage = require("../assets/menuitem.svg");
export const SearchImage = require("../assets/searchImage.svg");
export const TilesImage = require("../assets/tilesImage.svg");
export const GridImage = require("../assets/gridImage.svg");
export const productImg = require("../assets/productImage.svg");

