import React from "react";
// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Button, Container, Grid, Box, Card, CardMedia, CardContent,Tooltip, Typography, IconButton ,Divider } from "@mui/material";
import { styled } from "@mui/styles";
import { FavoriteBorder as FavoriteBorderIcon } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { CatalogueBanner, EqualImage, FilterImage, TilesImage, LeftArrow, MapImage, MenuItemImage, RightArrow, SearchImage, GridImage, productImg } from './assets';
// Customizable Area End

// Customizable Area Start
import CatalogueController, { CatalogueItem, Props, configJSON } from "./CatalogueController";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGridBox = () => { 
    return (
      <>
    <Loader loading={this.state.loading}/>
  <Grid style={{marginTop:"0px"}} container spacing={3}>
    {this.state.catalogueDataMain.map((item: CatalogueItem , index: number) => (
      <React.Fragment key={index}>
        {index === 14 && (
          <Grid item xs={12}>
          <Box
          sx={{
              flexGrow: 1,
              margin: "4px 6px 0px 17px"
          }}
      >
          <PillowCaseComponentBox container spacing={2} >
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} >
                  <Box sx={{ p: 2 }}>
                      <PillowCaseTitleComponent  gutterBottom>
                      {configJSON.pillowCaseTitle}
                      </PillowCaseTitleComponent>
                      <PillowCaseDescriptionComponent variant="body1" style={{color:"#162A26", fontSize: "15px" ,fontFamily:"brandon-grotesque, sans-serif"}} gutterBottom>
                      {configJSON.pillowCaseDescription}
                      </PillowCaseDescriptionComponent>
                      <Button style={{fontFamily:"brandon-grotesque, sans-serif",backgroundColor:"#FFF", marginTop: "19px", width: "135px ", borderRadius: "18px", color: "#B7862B", borderColor: "#B7862B", borderWidth: 1, borderStyle: "solid" }}>LEARN MORE</Button>
                  </Box>
              </Grid>
          </PillowCaseComponentBox>
      </Box>
       </Grid>
        )}
       
       {this.state.activeGridImage && (
       <Grid item xs={6} sm={4} md={3} lg={12 / 7}>
          <CardMainComponent style={{ padding: "5px",cursor:"pointer"}}>
            <div style={{ position: 'relative' }} data-test-id="grid-navigate-id" onClick={this.handleProductDescription}>
              <CardMediaComponent
              image={item.attributes?.pictures[0]?.url ?? productImg}
              />
              <IconButtonComponent style={{ position: 'absolute', top: 1, right: 0, zIndex: 1, color: 'white' }}>
                <FavoriteBorderIcon />
              </IconButtonComponent>
            </div>
            <CardContentComponent>
            <Tooltip title={item.attributes.display_name}>
      <Typography noWrap style={{ fontWeight:"420", fontSize: '16px', marginTop: "10px", fontFamily:"brandon-grotesque, sans-serif", color:"#162A26"}} gutterBottom>
        {item.attributes?.display_name?.length > 11 ? `${item.attributes.display_name?.substring(0, 11)}...` : item.attributes.display_name}
      </Typography>
    </Tooltip>
  <ContainerMain>
      <ItemNew>
        <PriceTypography>PRICE</PriceTypography>
        <Value>
        £0.45 /kg
          </Value>
      </ItemNew>
      <ItemNew>
        <PriceTypography>AVAILABLE</PriceTypography>
        <Value>
        150,000.00 kg
          </Value>
      </ItemNew>
    </ContainerMain>
              <CardContentRowLast>
                <div style={{ height: "14px", width: "14px", backgroundColor: "#D84539" }} />
              </CardContentRowLast>
            </CardContentComponent>
          </CardMainComponent>
        </Grid>
       )}

       {this.state.activeTiles && (
        <Grid item xs={12} sm={12} md={12} lg={6}>
        <CardComponentTiles>
          <div  style={{display:"flex", height:"82px", width:"100%" , cursor:"pointer"}}>
            <div data-test-id="tiles-navigate-id"  onClick={this.handleProductDescriptionFirst} style={{position: 'relative', padding:"2px"}}>
            <CardMediaImageComponent
            image={item.attributes.pictures[0]?.url ?? productImg}
          /> 
          <IconButtonComponent
            sx={{ position: 'absolute', top: 4, right: 4, color: '#FFF', }}
            size="small"
          >
              <FavoriteBorderIcon style={{width: "18px", height:"16px"}}  />
            </IconButtonComponent>
          </div>
         <div style={{width:"100%"}}>
          <CardContentTilesComponent style={{display:"flex"}}>
           <Grid item xs={12} sm={12} md={6} lg={6}>
        <TileTitleComponent>
          {item.attributes.display_name}
        </TileTitleComponent>
      </Grid>
      <Grid item xs={12} sm={12} lg={6} md={6}>
      <PriceContentComponent>
        <div>
        <ColoredBoxComponent />
        </div>
        <PriceMainComponent>
           <div>
        <TilePriceComponent>
          Price
        </TilePriceComponent>
        <TilePriceValueComponent>
          £29.35/m
        </TilePriceValueComponent>
      </div>
      <div>
      <TilePriceComponent>
          Available
        </TilePriceComponent>
        <TilePriceValueComponent>
          150 m
        </TilePriceValueComponent>
      </div>
        </PriceMainComponent>
      </PriceContentComponent>
      </Grid>
          </CardContentTilesComponent>
         </div>
          </div>
        </CardComponentTiles>
      </Grid>
        )}
      </React.Fragment>
    ))}
  </Grid>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <NavigationWrapper navigation={this.props.navigation} id="">
      <ContainerRoot>
                    <MarketCircularGridComponent container spacing={2} alignItems="center">
                        <ResponsiveGridCricular item xs={12} md={8} sm={12}>
                            <Title>{configJSON.circularMarketPLaceTitle}</Title>
                            <Subtitle>{configJSON.circularMarketPLaceDescription}</Subtitle>
                        </ResponsiveGridCricular>
                        <ResponsiveGrid style={{marginTop:"30px"}} item xs={12} md={4}>
                            <ResponsiveInnerGrid container spacing={1}>
                                <ResponsiveInnerDiv>
                                    <Grid item>
                                        <SearcgTextComponent style={{ fontWeight: "450",paddingRight:"5px" }}>Search</SearcgTextComponent>
                                    </Grid>
                                    <Grid item>
                                        <SearchImageComponent src={SearchImage} style={{paddingRight:"30px"}}/>
                                    </Grid>
                                    <Grid item>
                                        <FilterTextComponent style={{ fontWeight: "450",paddingRight:"5px" }}>Filter</FilterTextComponent>
                                    </Grid>
                                    <Grid item>
                                        <ImageComponent style={{paddingRight:"19px"}} src={FilterImage} />
                                    </Grid>
                                </ResponsiveInnerDiv>
                                
                                <ResponsiveInnerDiv>
                                <DividerComponent orientation="vertical" flexItem />
                                    <Grid item>
                                        <IconButton>
                                            <ImageComponent src={MapImage} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton style={{cursor:"pointer"}} data-test-id="tilesToggleId" onClick={this.handleActiveTiles}>
                                        {this.state.activeTiles ? (
                                           <ImageComponent src={TilesImage} />
                                        ):(
                                          <ImageComponent src={EqualImage} />
                                        )}
                                            
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton data-test-id="gridToggleId" onClick={this.handleActiveGrid}>
                                        {this.state.activeGridImage ? (
                                           <ImageComponent src={MenuItemImage} />
                                        ):(
                                          <ImageComponent src={GridImage} />
                                        )}
                                           
                                        </IconButton>
                                    </Grid>

                                </ResponsiveInnerDiv>

                            </ResponsiveInnerGrid>
                            
                        </ResponsiveGrid>
                    </MarketCircularGridComponent>
                   {this.renderGridBox()}
                    <ContainerBoxCoponent>
                        <StyledPagination
                            count={10}
                            renderItem={(item) => (
                                <>
                                    <StyledPaginationItem
                                        slots={{
                                            previous: () => <img src={LeftArrow} alt="Previous" />,
                                            next: () => <img src={RightArrow} alt="Next" />
                                        }}
                                        {...item}
                                    />
                                </>

                            )}
                        />
                    </ContainerBoxCoponent>
                </ContainerRoot>
                </NavigationWrapper>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const PillowCaseDescriptionComponent = styled(Typography)({
  margin: "0px 15px 0.35em 0px"
})

const ContainerMain = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const ItemNew = styled('div')({
  textAlign: 'center',
});

const Value = styled(Typography)({
  fontSize: '14px', 
  color: "#162A26",
   fontFamily: "brandon-grotesque, sans-serif",
   fontWeight:420,
   textAlign:"left"
});

 const CardComponentTiles = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important"
})

const CardMainComponent = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.9) 0px 0px 1px -1px, rgba(0, 0, 0, 0.14) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px !important"})


const PillowCaseTitleComponent = styled(Typography)({
  fontSize: "24px",
  fontFamily:"garamond-premier-pro !important",
  color:"#162A26"
})

const CardContentTilesComponent = styled(CardContent)({
  '@media (max-width: 420px)': {
    flexDirection:"column",
  },
  '@media (max-width: 360px)': {
     flexDirection:"column",
  },
  '@media (max-width: 430px)': {
    flexDirection:"column",
 },
})

const PriceContentComponent = styled('div')({
  display:"flex",
   alignItems: "center", 
   justifyContent:"space-between", 
   width:"100%" ,
   '@media (max-width: 420px)': {
    alignItems:"center"
  },
  '@media (max-width: 360px)': {
     alignItems:"center"
  },
  '@media (max-width: 430px)': {
    alignItems:"center"
 },
})

const TilePriceValueComponent = styled(Typography)({
  fontWeight: 420,
   color: '#162A26',
    fontFamily:"brandon-grotesque, sans-serif",
    fontSize:"14px"
})

const ColoredBoxComponent = styled('div')({
  height: "14px", width: "14px", backgroundColor: "#F6D7C9"
})

const PriceMainComponent = styled('div')({
  display:"flex",
   width:"74%", 
   justifyContent:"space-between"
})

const TilePriceComponent = styled(Typography)({
  fontWeight: 400, 
  color: '#2D6A4D',
  fontSize:"11px",
  fontFamily:"garamond-premier-pro !important",
  textTransform:"uppercase"
})

const TileTitleComponent = styled(Typography)({
fontWeight:420,
fontSize:"16px",
fontFamily:"brandon-grotesque, sans-serif",
color: '#162A26'
})
const FilterTextComponent = styled(Typography)({
  color:"#162A26",
  fontFamily:"brandon-grotesque, sans-serif",
  '@media (max-width: 820px)': {
    paddingLeft:"10px"
  },
  '@media (min-width: 360px)': {
     paddingLeft:"10px" 
  },
})

const CardMediaImageComponent = styled(CardMedia)({
  height:"78px",
  width:"92px",
})
const IconButtonComponent = styled(IconButton)({
  "& .css-i4bv87-MuiSvgIcon-root": {
      width: "18px",
      height: "16px"
  }
});

const ContainerRoot = styled(Container)({
  flexGrow: 1,
  padding: "24px",
  '@media (min-width: 1200px)': {
    '&.MuiContainer-root': {
      maxWidth: '1600px',
    },
  },
});

const MarketCircularGridComponent = styled(Grid)({
  '@media (max-width: 960px)': {
    marginTop:"6px"

},
'@media (max-width: 820px)': {
  marginTop:"6px !important"
},
'@media (max-width: 600px)': {
    marginTop:"6px !important"
},
})

const CardMediaComponent = styled(CardMedia)({
  height: "140px"
});

const PriceTypography = styled(Typography)({
  fontWeight: 400,
  fontFamily:"garamond-premier-pro !important",
  color: "#2D6A4D",
   fontSize: '11px' ,
   textAlign: "left"
});

const AvailableTypography = styled(Typography)({
  marginLeft: "16px",
  fontWeight: 400,
  fontFamily:"garamond-premier-pro !important",
  paddingRight:"26px"
});

const CardContentRow = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const CardContentComponent = styled("div")({
  padding: "4px",
  "& .css-46bh2p-MuiCardContent-root:last-child": {
      paddingBottom: "5px"
  }
});

const CardContentRowLast = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "19px"
});


const ContainerBoxCoponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0',
  backgroundColor: "#F1F5F9",
  marginTop: "17px"
});

const StyledPagination = styled(Pagination)({
  width: "100%",
  '& .MuiPagination-ul': {
      position: 'relative',
      justifyContent: 'center',
      width: "100%"
  },
  '& .MuiPagination-ul li:first-child': {
      position: 'absolute',
      left: 0,
  },
  '& .MuiPagination-ul li:first-child button::before': {
      content: '"Previous"',
      position: 'absolute',
      left: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      color: "#045544",
      fontFamily:"brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:last-child': {
      position: 'absolute',
      right: 0,
  },
  '& .MuiPagination-ul li:last-child button::before': {
      content: '"Next"',
      position: 'absolute',
      right: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      color: "#045544",
      fontFamily:"brandon-grotesque, sans-serif",
  },
  '& .MuiPagination-ul li:first-child button:hover, & .MuiPagination-ul li:last-child button:hover': {
      backgroundColor: 'unset',
  },
  '@media (max-width: 420px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    },'& .MuiPagination-ul li:first-child button::before':{
      content: '""',
    }
  },
  '@media (max-width: 430px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    },'& .MuiPagination-ul li:first-child button::before':{
      content: '""',
    }
  },
  '@media (max-width: 360px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    },'& .MuiPagination-ul li:first-child button::before':{
      content: '""',
    },'& .css-whh4o0-MuiButtonBase-root-MuiPaginationItem-root':{
      margin:"0px"
    },
    ' & .MuiPagination-ul li:last-child' :{
      right: "0",
      position: "absolute",
      left: "91%",
    }
  },
  '@media (max-width: 320px)': {
    '& .MuiPagination-ul li:last-child button::before': {
      content: '""',
    },'& .MuiPagination-ul li:first-child button::before':{
      content: '""',
    }
  },
});

const StyledPaginationItem = styled(PaginationItem)({
  '&.MuiPaginationItem-root.Mui-selected': {
      backgroundColor: '#045544',
      color: '#fff',
      width: "100%"
  },
  '&.MuiPaginationItem-root': {
      borderRadius: '4px',
      color: "#045544"

  },
});

const PillowCaseComponentBox = styled(Grid)({
  justifyContent: "flex-end",
  alignItems: "center",
  background: `url(${CatalogueBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height:"349px"
})

const Title = styled('h1')({
  fontSize: '24px',
  color: '#2e7d32',
  margin: 0,
  fontWeight: 450,
  alignItems: "center",
  fontFamily:"brandon-grotesque, sans-serif",
  '@media (max-width: 960px)': {
     width:"100%"
  },
  '@media (max-width: 840px)': {
    width:"100%"
  },
  '@media (max-width: 420px)': {
    width:"100%"
  },
});

const Subtitle = styled('p')({
  fontSize: '15.098px',
  color: '#162A26',
  margin: 0,
  fontWeight: 420,
  fontFamily:"brandon-grotesque, sans-serif",

});

const ResponsiveGrid = styled(Grid)({
  marginTop:"10px",
  '@media (max-width: 960px)': {
      textAlign: 'left',
      marginTop:"10px"
  },
  '@media (max-width: 600px)': {
      textAlign: 'left',
      marginTop:"10px",
      "& .css-ofrcno-MuiGrid-root > .MuiGrid-item":{
        marginTop:"15px"
      }
  },
});

const ResponsiveGridCricular = styled(Grid)({
  height: "56px",
  '@media (max-width: 960px)': {
    textAlign: 'left',

},
'@media (max-width: 600px)': {
    textAlign: 'left',
},
})

const ResponsiveInnerGrid = styled(Grid)({
  justifyContent: "flex-end",
  alignItems: "center",
  '@media (max-width: 960px)': {
      textAlign: 'center',
      justifyContent: 'space-between',
      marginTop:"10px"
  },
  '@media (max-width: 840px)': {
      textAlign: 'center',
      justifyContent: 'space-between',
      marginTop:"10px"
  },
  '@media (max-width: 420px)': {
      textAlign: 'center',
      justifyContent: 'space-between',
      marginTop:"10px"
  },

  '@media (max-width: 360px)': {
    textAlign: 'center',
    justifyContent: 'space-between',
    marginTop:"10px"
},
})

const ResponsiveInnerDiv = styled(Grid)({
   display:"flex",
   alignItems:"center"
})

const ImageComponent = styled("img")({
  width:"17px",
  height:"17px",
})

const DividerComponent = styled(Divider)({
  '@media (max-width: 840px)': {
      display: "none"
  },
  '@media (max-width: 420px)': {
      display: "none"
  },
})

const SearchImageComponent = styled("img") ({
  '@media (max-width: 840px)': {
      paddingRight: "10px"
  },
  '@media (max-width: 420px)': {
      paddingRight: "10px"
  },
})

const SearcgTextComponent = styled(Typography)({
  paddingLeft:"10px",
  fontFamily:"brandon-grotesque, sans-serif",
  color:"#162A26"
})
// Customizable Area End
