import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const countryList = require("react-select-country-list");
import { SelectChangeEvent } from '@mui/material';
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export interface SignUpSuccessResponse {
  data: {
    id: string,
    type:string,
    attributes: {
        first_name: string,
        email: string
    }
  },
  meta: {
        token: string
  }
}

export interface SignUpResponse {
  data: {
    id: string,
    type:string,
    attributes: {
        first_name: string,
        email: string
    }
  },
  meta: {
        token: string
  }
  errors:Array<string>
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  userType: string;
  apiError:string

  confirmPassword: string;
  jobTitle: string;
  companyName: string;
  city: string;
  phone: string;
  selectedCountry: string;
  countryOptions: { label: string; value: string }[];
  //states for Error
  firstNameError:string;
  lastNameError:string;
  emailError: boolean;
  jobTitleError:string;
  cityError:string;
  companyNameError:string;
  phoneError:string;
  countryError:string;
  passwordError: boolean;
  confirmPasswordError:boolean;
  //States for ErrorMessage
  firstNameErrorMessage:string
  lastNameErrorMessage:string
  emailErrorMessage:string;
  passwordErrorMessage:string;
  confirmPasswordErrorMessage: string;
  jobTitleErrorMessage:string
  cityErrorMessage:string
  companyNameErrorMessage:string
  phoneErrorMessage:string
  countryErrorMessage:string

  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  surname: string;
  workEmail: string;
  showErrorPopup:boolean,
  tncChecked:boolean,
  countryCode: string;
  showTnCEror: boolean;
  fType:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      userType: "buyer",
      showErrorPopup:false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      jobTitle: "",
      city: "",
      companyName: "",
      phone: "",
      selectedCountry: "",
      countryOptions: countryList().getData(),
      // states for error
      firstNameError:"",
      lastNameError:"",
      emailError: false,   
      passwordError: false,
      confirmPasswordError: false,
      jobTitleError:"",
      cityError:"",
      companyNameError:"",
      phoneError:"",
      countryError:"",  
      apiError:'' ,
      // state For error message
      firstNameErrorMessage:"",
      lastNameErrorMessage:"",
      emailErrorMessage:"",
      passwordErrorMessage:"",   
      confirmPasswordErrorMessage:"", 
      jobTitleErrorMessage:"",
      cityErrorMessage:"",
      companyNameErrorMessage:"",
      phoneErrorMessage:"",
      countryErrorMessage:"",
      showPassword: false,
      showConfirmPassword: false,
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      surname: "",
      workEmail: "",
      tncChecked:false,
      countryCode:"",
      showTnCEror:false,
      fType:"signUp"
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.signUpResponseHandler(responseJson)
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  handleCancel=()=>{
    this.setState({showErrorPopup: false})
  }
  getColor = (color: string)=>{
    let value = ""
    value = this.state.showTnCEror ? "#DC2626" : color
    return value
  }
  clickSignUp(){
    this.setState({fType:'signUp',
      firstName:"",
      lastName:"",
      email:"",
      selectedCountry:"",
      companyName:"",
      city:"",
      jobTitle:"",
      password:"",
      confirmPassword:"",
      countryCode:"",
      phone:"",
      tncChecked:false
    })
  }
  signUpResponseHandler(response:SignUpResponse){
    if(response.data){
      toast.success("Please verify your email address to continue. Check your inbox for the verification link.");
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    if(response.errors){
      const companyError = response.errors.filter((item:any) => (typeof item === 'object' && item.hasOwnProperty('company_name')));
      if(companyError?.length > 0){
        this.setState({fType:'companyError'})
      }
      else{
        this.handleApiError(response.errors)
      }
    }
  }
  handleApiError = (errors: any) => {
    const filteredArray = errors.filter((item:any) => !(typeof item === 'object' && item.hasOwnProperty('company_name')));
    const errorMessages = filteredArray.join(" ") 
    toast.error(errorMessages);
    return "";
  };
  createAccount(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpBody = {
      data:{
        type: "email_account",
        attributes:{
          account_type:this.state.userType,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: this.state.email,
          country: this.state.selectedCountry, 
          city: this.state.city, 
          company_name: this.state.companyName, 
          job_title: this.state.jobTitle,
          password: this.state.password,
          phone_number: this.state.phone,
          country_code:this.state.countryCode
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };
  handleCheckboxChange =(value:boolean)=>{
    this.setState({tncChecked:value},()=>{
      if(this.state.tncChecked){
        this.setState({showTnCEror:false})
      }
    })
  }
  async componentDidMount() {
    const authToken = await getStorageData("accessToken");
    if(authToken){
      this.props.navigation.navigate("Catalogue")
    }
  }
  handleChange = (name:string, value:string): void => {
    if(name === 'phone'){
      value = value.replace(/\D/g, '');
    }
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>,()=>{
      if (name === 'firstName') {
        this.validatefirstNameField();
      }
      else if(name === 'jobTitle'){
        this.validateJobField();
      }
      else if(name === 'lastName'){
        this.validatesurnameField();
      }
      else if(name === 'companyName'){
        this.validatecompanyNameField();
      }
      else if(name === 'email'){
        this.validateworkEmailField();
      }
      else if(name === 'city'){
        this.validatecityField();
      }
      else if(name === 'phone'){
        this.validatephoneField();
      }
      else if(name === 'password'){
        this.validatePassword();
      }
      else if(name === 'confirmPassword'){
        this.validateConfirmPassword();
      }
    });
  };

  validatefirstNameField = () => {
    const { firstName } = this.state;
    if (firstName.length === 0) {
      this.setState({ firstNameErrorMessage: "This field can't be empty" });
    } else {
      this.setState({ firstNameError: '' });
    }
  }
  validateJobField = () => {
    const { jobTitle } = this.state;
    if (jobTitle.length === 0) {
      this.setState({ jobTitleErrorMessage: "This field can't be empty"});
    } else {
      this.setState({ jobTitleError: '' });
    }
  }
  validatesurnameField = () => {
    const { lastName } = this.state;
    if (lastName.length === 0) {
      this.setState({ lastNameError: "This field can't be empty" });
    } else {
      this.setState({ lastNameError: '' });
    }
  }
  validatecompanyNameField = () => {
    const { companyName } = this.state;
    if (companyName.length === 0) {
      this.setState({ companyNameErrorMessage: "This field can't be empty" });
    } else {
      this.setState({ companyNameError: '' });
    }
  }
  validateEmail = (email:string) => {
    const atSymbolIndex = email.indexOf('@');
    const dotSymbolIndex = email.lastIndexOf('.');
    return (
      atSymbolIndex > 0 &&
      dotSymbolIndex > atSymbolIndex + 1 &&
      dotSymbolIndex < email.length - 1
    );
  }
  validateworkEmailField = () => {
    const { email } = this.state;
    if (!this.validateEmail(email)) {
      this.setState({ emailErrorMessage: "Please enter a valid email.",emailError:true });
    } else {
      this.setState({ emailError: false,emailErrorMessage:'' });
    }
  }
  validatecityField = () => {
    const { city } = this.state;
    if (city.length === 0) {
      this.setState({ cityErrorMessage: "This field can't be empty" });
    } else {
      this.setState({ cityError: '' });
    }
  }
  validatephoneField = () => {
    const { phone } = this.state;
    if (phone.length < 6 || phone.length > 15) {
      this.setState({ phoneError: "Please enter a valid mobile number" });
    } else {
      this.setState({ phoneError: '' });
    }
  }

 
  validatePassword = (): boolean => {
    const { password } = this.state;
    let wordArray = ['P', 'a', 's', 's', 'w', 'o', 'r', 'd'];
   
    if (password.length < 8 || password.search(/[a-z]/) < 0 || password.search(/[A-Z]/) < 0 || 
      password.search(/\d/) < 0 || password.search(/[\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:\-]/) < 0) {
      this.setState({ passwordErrorMessage: wordArray.join('')+ " " + configJSON.passMsg ,passwordError: true });
      return false;
    }
    this.setState({ passwordError: false, passwordErrorMessage:'' });
    return true;
  };

  validateConfirmPassword = (): boolean => {
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.setState({
        confirmPasswordErrorMessage: configJSON.confirmPasswordValidateError,
        confirmPasswordError: true
      });
      return false;
    }
    this.setState({ confirmPasswordError: false, confirmPasswordErrorMessage:''});
    return true;
  };

  handleUserTypeChange = (role: string) => {
    this.setState({ userType: role });
  };


  handleCountryChange = (event: SelectChangeEvent<unknown>) => {
    const selectedOption = event.target.value as string;
    let countryCode ="";
    switch (selectedOption) {
      case "United Kingdom":
        countryCode = "44"
        break;
      case "Germany":
        countryCode = "49"
        break;
      case "Spain":
        countryCode = "34"
        break;
      case "France":
        countryCode = "33"
        break;
      case "Italy":
        countryCode = "39"
        break;
      case "Portugal":
        countryCode = "351"
        break;
      default:
        break;
    }
    this.setState({ selectedCountry: selectedOption,countryCode:countryCode },()=>{
      const { selectedCountry } = this.state;
      if (selectedCountry.length === 0) {
        this.setState({ countryError: "This field can't be empty" });
      } else {
        this.setState({ countryError: '' });
      }
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };
  checkValidation(){
    let invalidPasswordMessage = ''
    let invalidConfirmPasswordMessage = "";
    let errorInPassword = false;
    let errorInCPassword = false;
    let wordArray = ['P', 'a', 's', 's', 'w', 'o', 'r', 'd'];
    if (this.state.confirmPassword.length == 0) {
      invalidConfirmPasswordMessage = "This field can't be empty";
      errorInCPassword = true;
    }
    if (this.state.password.length < 8 || this.state.password.search(/[a-z]/) < 0 || this.state.password.search(/[A-Z]/) < 0 || 
      this.state.password.search(/\d/) < 0 || this.state.password.search(/[\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:\-]/) < 0) {
      invalidPasswordMessage = wordArray.join('')+ " " + configJSON.passMsg;
      errorInPassword = true;
    } 
    if (this.state.password.length === 0) {
      invalidPasswordMessage = "This field can't be empty";
      errorInPassword = true;
    }
    if (this.state.password !== this.state.confirmPassword) {
      invalidConfirmPasswordMessage = configJSON.confirmPasswordValidateError;
      errorInCPassword = true;
    }
    return {
      invalidPasswordMessage,
      invalidConfirmPasswordMessage,
      errorInPassword,
      errorInCPassword
    }
  }
  validateRegistrationData(): boolean {  
    let emailError = false;
    let emailErrorMsg = "";
    let showTnCEror = false;

    showTnCEror = this.state.tncChecked ? false : true
    
    if(!this.validateEmail(this.state.email)) {
      emailError = true;
      emailErrorMsg = "Please enter a valid email."
    }
    if(this.state.email.length === 0){
      emailError = true;
      emailErrorMsg = "This field can't be empty"
    }
    
    let invalidPasswordMessage = "";
    let errorInPassword = false;

    invalidPasswordMessage = this.checkValidation().invalidPasswordMessage;
    errorInPassword = this.checkValidation().errorInPassword;
  
    let invalidConfirmPasswordMessage = "";
    let errorInCPassword = false;

    invalidConfirmPasswordMessage = this.checkValidation().invalidConfirmPasswordMessage;
    errorInCPassword = this.checkValidation().errorInCPassword;

    const isEmptyFirstName = this.state.firstName.length < 1;
    const emptyFirstNameError = isEmptyFirstName ? "This field can't be empty" : "";
  
    const isEmptyLastName = this.state.lastName.length < 1;
    const emptyLastNameError = isEmptyLastName ? "This field can't be empty" : "";
  
    const isEmptyPhoneNumber = this.state.phone.length < 1;
    const emptyPhoneNumberError = isEmptyPhoneNumber ? "This field can't be empty" : "";
  
    const isEmptyCountry = this.state.selectedCountry.length < 1;
    const emptyCountryError = isEmptyCountry ? "This field can't be empty" : "";
  
    const isEmptyJobTitle = this.state.jobTitle.length < 1;
    const emptyJobTitleError = isEmptyJobTitle ? "This field can't be empty" : "";
  
    const isEmptyCity = this.state.city.length < 1;
    const emptyCityError = isEmptyCity ? "This field can't be empty" : "";
  
    const isEmptyCompanyName = this.state.companyName.length < 1;
    const emptyCompanyNameError = isEmptyCompanyName ? "This field can't be empty" : "";
  
    this.setState({
      firstNameError: emptyFirstNameError,
      lastNameError: emptyLastNameError,
      jobTitleError: emptyJobTitleError,
      companyNameError: emptyCompanyNameError,
      countryError: emptyCountryError,
      cityError: emptyCityError,
      phoneError: emptyPhoneNumberError,
      emailError: emailError,
      emailErrorMessage: emailErrorMsg,
      passwordErrorMessage: invalidPasswordMessage,
      passwordError: errorInPassword,
      confirmPasswordError:errorInCPassword,
      confirmPasswordErrorMessage: invalidConfirmPasswordMessage,
      showTnCEror : showTnCEror
    });
  
    if (showTnCEror || emailError || errorInPassword || errorInCPassword || isEmptyFirstName || isEmptyLastName || isEmptyPhoneNumber || isEmptyCountry || isEmptyJobTitle || isEmptyCity || isEmptyCompanyName) {
      return false;
    }
    
    return true;
  }
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(this.validateRegistrationData()){
      if(this.state.tncChecked){
        this.createAccount()
      }
      else{
        this.setState({showTnCEror: true})
      }
    }
  };

  roleStyle = (role: string) => ({
    color: this.state.userType === role ? "#BA9553" : "#616161",
    font: "Brandon Grotesque",
    fontSize: "16px",
    fontWeight: "500",
    marginLeft: "5px",
    borderBottom: this.state.userType === role ? "1px solid #BA9553" : "none",
    cursor: "pointer",
  });
  
  // Customizable Area End
}
