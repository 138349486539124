import React from "react";

// Customizable Area Start
import {
  Box,
  Container
} from "@mui/material";
import {  styled } from '@mui/material/styles';
import { colors } from "../../../blocks/utilities/src/Colors";
import UserSettingsController, {
  Props,
  configJSON,
} from "./UserSettingsController.web";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";
import { PersonalDetails } from "./PersonalDetails.web";
import {CompanyDetails} from "../../../blocks/customisableuserprofiles/src/CompanyDetails.web";
// Customizable Area End


export class UserSettings extends UserSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab } = this.state;
    const { settingTabs } = configJSON;

    const renderContent = () => {
      return (
        <>
          {selectedTab === settingTabs[1].link ? (
            <CompanyDetails {...this.props} />
          ) : (
            <PersonalDetails {...this.props} />
          )}
        </>
      );
    };

    return (
      <NavigationWrapper {...this.props} >
        <SettingWrapper>
          <div className={"settingsTitle"}>{configJSON.title}</div>
          <div className={"common"}>
            {configJSON.settingTabs.map((item: { link: string, title: string }) => (
              <Box
              data-test-id="tab_change"
                key={item.link}
                className={`menuItemStyle ${selectedTab === item.link && "selected"}`}
                onClick={() => this.handleTabChange(item.link)}
              >
                {item.title}
              </Box>
            ))}
          </div>

          {renderContent()}
        </SettingWrapper>

      </NavigationWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SettingWrapper = styled(Container)({
  height: "100%",
  width: "100%",
  maxWidth: "1225px",
  padding: "31px 51px 51px",

  '@media (max-width: 768px)': { 
    padding: "27px 20px 20px",
  },
  '@media (max-width: 480px)': { 
    padding: "25px 12px 12px",
  },

  "& .settingsTitle": {
    fontSize: '24px',
    color: '#445D40',
    width: '100%',
    textAlign: 'left',
    fontWeight: 420,
    lineHeight: '34.32px',
    marginBottom: '10px',
  }, 
  "& .common": {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    overflowX: "auto",
    padding: '10px 0',
    marginBottom: '10px',

    '@media (max-width: 1024px)': {
      gap: '8px',
    },
  
  },
  "& .menuItemStyle": {
    minWidth: "fit-content",
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 450,
    transition: '0.3s ease',
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'left',
    padding: '8px 12px',
    borderRadius: '8px',
    // marginLeft: '10px',
    '&.selected': {
      color: colors().darkGold,
      background: colors().lightGold,
    },
    '&:hover': {
      color: colors().darkGold,
      background: colors().lightGold,
    },
  },

  "& .menuContentParent": {
    float: 'left',
    width: '100%',
    height: 'auto',
  },
});
// Customizable Area End
