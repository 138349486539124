import React from "react";

// Customizable Area Start
import {
  Box,Typography
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { image, mobileImage, tabImage} from "./assets";
import ContactusController, { Props } from "./ContactusController";
import { NavigationWrapper } from "../../../blocks/navigationmenu/src/NavigationWrapper.web";

// Customizable Area End
export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <NavigationWrapper navigation={this.props.navigation} id={""} >
      <MainDiv style={this.state.authToken ? {paddingLeft:"3%"}: {paddingLeft:"15%"}}>
        <MainLabel>
          <StyledLabel> CONTACT US</StyledLabel> 
        </MainLabel>
        <SubDiv>
        <StyledLabel2>
            Be sustainable by using our premium, verified circular materials, sold at a low-cost.
        </StyledLabel2>
        </SubDiv>
        <ContentDiv>
        <StyledLabel3>
            How would you like to 
            <span style={{color:"#B7862B"}}>{" "}get in touch</span> with us?
        </StyledLabel3>
        </ContentDiv>
        <DetailContainer>
          <ContentDiv1>
               <ContentDiv2>
                <StyledLabel4>Email Us</StyledLabel4>
                <StyledLabel5>
                  Reach out to us via email. Click the button below to open your email client with our address pre-filled.
                </StyledLabel5>
                <StyledButton><a style={{color: "#FAFAF9",textDecoration:"none"}} href="mailto:admin@procurecircular.com">Email</a></StyledButton>
              </ContentDiv2>
              <ContentDiv2>
                <StyledLabel4>Contact Form</StyledLabel4>
                <StyledLabel5>
                Fill out our contact form and we'll get back to you shortly. Click the button below to start.
                </StyledLabel5>
                <StyledButton>Contact Us</StyledButton>
              </ContentDiv2>
              
              <ContentDiv2>
                <StyledLabel4>Call Us</StyledLabel4>
                <StyledLabel5>
                Speak to us directly. Click the button below to copy our number to your clipboard or start a call.
                </StyledLabel5>
                <StyledButton><a href="tel:+44 (0) 20 8064 0327" style={{color: "#FAFAF9",textDecoration:"none"}}>Call Us</a></StyledButton>
              </ContentDiv2>
              <ContentDiv2>
                <StyledLabel4>Live Chat</StyledLabel4>
                <StyledLabel5>
                Chat with our support team for immediate assistance. Click the button below to start a live chat.
                </StyledLabel5>
                <StyledButton>Start Chat</StyledButton>
              </ContentDiv2>
              <ContentDiv6>
                <StyledLabel4>
                  FAQ
                </StyledLabel4>
                <StyledLabel5>
                  Have questions? Our FAQ section is here to help! Explore our comprehensive list of
                  frequently asked questions to find quick and detailed answers to common inquiries about our services. Click the button below to start browsing our FAQs.
                </StyledLabel5>
                <StyledButton1 >Explore FAQ's</StyledButton1>
              </ContentDiv6>
              <ContentDiv3>
                <StyledLabel6>
                  Mailing Address
                </StyledLabel6>
                  <Typography>PO Box 28190, RPO West Pender</Typography>
                  <Typography>Vancouver BC V6C 3T7 London</Typography>
              </ContentDiv3>
              <ContentDiv4>
                  <Typography>Worldwide Toll-Free:</Typography>
                  <Typography style={{color:'#31936A'}}>+1-888-463-5254</Typography>
                  <Typography>Mon-Fri 8:00AM to 4:00PM (PST)</Typography>
              </ContentDiv4>
                
          </ContentDiv1>
          <MapDiv></MapDiv> 
        </DetailContainer>
      </MainDiv>
      </NavigationWrapper>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledLabel = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 24,
  fontWeight: "420",
  textAlign: "left",
  color: "#2D6A4D"
});
const StyledLabel2 = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 15.1,
  fontWeight: "420",
  textAlign: "left",
  color: "#162A26",
  letterSpacing: 0.03,

});
const MapDiv = styled(Box)({
  width: "50%",
  height: "auto",
  gap: "54px",
  opacity: "0px",
  backgroundImage:`url(${image})`,
  backgroundSize:"cover",
  marginTop:"-85px",
  "@media(max-width:600px)": {
    marginTop: "28px",
    height: "354px",
    width:'90%',
    backgroundImage:`url(${mobileImage})`,
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    marginTop:"-33px",
    height: "354px",
    width:'86%',
    backgroundImage:`url(${tabImage})`,
  }
});
const MainDiv = styled(Box)({
  backgroundColor: "#fff",
  paddingTop:'2%',
  width:"85%",
  "@media(max-width:600px)": {
    paddingLeft:'10% !important',
    width:"90%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    paddingLeft:'10% !important',
    width:"90%",
  }
});
const MainLabel = styled(Box)({
  width: "316px",
  height: "34px",
  gap: "0px",
  opacity: "0px",
 "@media(max-width:600px)": {
    left: "5%",
    height: "34px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    top: "70px",
    left: "7%",
    height: "34px",
  }
});
const DetailContainer = styled(Box)({
  display:"flex",
  width:"100%",
 "@media(max-width:600px)": {
    flexDirection: "column-reverse",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    flexDirection: "column-reverse",
  }
});
const SubDiv = styled(Box)({
  width:"50%",
  height: "18px",
  gap: "0px",
  opacity: "0px",
  "@media(max-width:600px)": {
    height: "70px",
    width:"95%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    top: "105px",
    left: "7%",
    height: "34px",
    width:"95%",
  }
});
const ContentDiv = styled(Box)({
  width: "50%",
  height: "24px",
  gap: "0px",
  opacity: "0px",
  marginTop:'40px',
  "@media(max-width:600px)": {
    height: "100px",
    width:"83%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    top: "168px",
    left: "7%",
    height: "100px",
    width:"83%",
  }
});
const StyledLabel3 = styled(Typography)({
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 24,
  fontWeight: "450",
  textAlign: "left",
  color: "#1C1917",
  letterSpacing: 0.03,
  textTransform:'uppercase'
});
const ContentDiv1 = styled(Box)({
  width: "50%",
  display:"flex",
  gap: "54px",
  opacity: "0px",
  flexFlow: "wrap",
  height: "auto",
  "@media(max-width:600px)": {
    width: "100%",
    height: "auto",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    top: "693px",
    left: "0%",
    height: "auto",
    width:"85%",
  }
});
const ContentDiv2 = styled(Box)({
  width: "44%",
  height: "176",
  gap: "20px",
  opacity: "0px",
  marginTop:"50px",
  "@media(max-width:600px)": {
    width: "86%",
    marginTop:"20px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"44%",
  }
});
const ContentDiv3 = styled(Box)({
  width: "44%",
  height: "176",
  gap: "20px",
  opacity: "0px",
  marginTop:"50px",
  "@media(max-width:600px)": {
    width: "86%",
    marginTop:"94px",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"44%",
  }
});
const ContentDiv4 = styled(Box)({
  width: "44%",
  gap: "20px",
  opacity: "0px",
  marginTop:"50px",
  "@media(max-width:600px)": {
    width: "86%",
    marginTop:"-103px"
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"44%",
  }
});
const StyledLabel4 = styled(Typography)({
  width: "84%",
  height: "32px",
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 21,
  fontWeight: "700",
  textAlign: "left",
  color: "#002E33",
  letterSpacing: 0.03,
});
const StyledLabel5 = styled(Typography)({
  width: "84%",
  height: "72px",
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: 16,
  fontWeight: "400",
  textAlign: "left",
  color: "#3C434A",
  gap:'20px',
  marginTop: "20px",
});
const StyledLabel6 = styled(Typography)({
  height: "32px",
  fontFamily: "brandon-grotesque, sans-serif",
  fontSize: "22px",
  fontWeight: "400",
  textAlign: "left",
  color: "#002E33",
  letterSpacing: 0.03,
  paddingBottom:"20px",
  "@media(max-width:600px)": {
    width: "86%",
  },
  "@media(min-width:600px) and (max-width:1100px)": {
    width:"100%",
  }
});
const StyledButton = styled(Box)({
  width: "100px",
  height: "32px",
  cursor:"pointer",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "36px",
  backgroundColor: "#1C1917",
  fontSize: 16,
  color: "#FAFAF9",
  marginTop: "40px",
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  "@media(min-width:500px) and (max-width:610px)": {
    marginTop: "48px",
  }
});
const StyledButton1 = styled(Box)({
  width: "115px",
  height: "32px",
  padding: "6px 12px 6px 12px",
  gap: "6px",
  borderRadius: "36px",
  cursor:"pointer",
  "@media(max-width:600px)": {
    marginTop:"109px"
  },
  backgroundColor: "#1C1917",
  fontSize: 16,
   color: "#FAFAF9",
   marginTop: "40px",
   textAlign: "center",
   alignItems: "center",
   display: "flex",
   justifyContent: "center"
});
const ContentDiv6 = styled(Box)({
  "@media(max-width:600px)": {
    width: "86%",
  },
  width: "620px",
  height: "176",
  gap: "20px",
  opacity: "0px",
});

// Customizable Area End